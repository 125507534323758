import React from 'react'

// Hooks
import useUtms from 'src/hooks/useUtms'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  return (
    <Section className='py-5 bg-grayscale--400 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 pr-0'>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 fw-600 text-white'>
              <span className='d-xl-block'>Capital de Giro para</span> <span className='d-xl-block'>empresas: apoio para</span> levar seu negócio além
            </h1>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22 fw-400 text-white'>
              <span className='d-xl-block'>Garanta linha de crédito segura, com contratação</span> <span className='d-xl-block'>simplificada e taxas que cabem no seu bolso. Tire seus</span> projetos do papel com o Capital de Giro FGI Peac! 
            </p>
            <a
              href={utmLink}
              target='_blank'
              rel='noreferrer'
              data-home-pj='hero'
              className='fs-14 btn text-white btn--lg text-none rounded-3 my-2 fw-700'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  section_name: 'Capital de Giro para empresas: apoio para levar seu negócio além',
                  element_name: 'Abrir Conta PJ gratuita',
                  redirect_url: utmLink,
                })
              }}
            >
              Abrir Conta PJ gratuita
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero

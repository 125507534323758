import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Card } from './style'

import fgipeacJSON from '../../assets/data/FGIPEAC.json'

type fgipeacProps = {
  title: string;
  description: string;
  icon: string;
}

const FGIPEAC = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-3 fw-600 text-grayscale--500 text-md-center'>
              FGI PEAC: o Capital de Giro feito sob medida para sua empresa
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-400 text-grayscale--500 text-md-center'>
              Aproveite o FGI Peac do Inter Empresas e saia na frente
            </p>
          </div>
          <div className='row mt-md-3'>
            {
              fgipeacJSON.map((item: fgipeacProps) => (
                <div key={item.icon} className='col-12 col-md-4'>
                  <Card className='pt-3 pb-3 px-3 mb-md-3'>
                    <div className='d-flex justify-content-start flex-md-column align-items-center align-items-md-start'>
                      <div className='mr-3'><OrangeIcon size='MD' color='#6A6C72' icon={item.icon} /></div>
                      <div><h3 className='fs-16 fs-lg-20 lh-20 lh-lg-25 fw-600 text-grayscale--500 mt-3 mb-0'>{item.title}</h3></div>
                    </div>
                    <p className='fs-14 fs-lg-16 lh-17 lh-lg-20 fw-400 text-grayscale--400 mt-2 mt-lg-3'>{item.description}</p>
                  </Card>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default FGIPEAC

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const Section = styled.section`

  a {
    background: ${green[500]};  
  }

  @media ${device.tablet} {
    height: 471px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-banner-capital-giro/image.webp');
    background-size: 1023px;
    background-repeat: no-repeat;
    background-position: top center;
  }

  @media ${device.desktopXL} {
    height: 815px;
    background-size: cover;
  }

  a {

    @media ${device.tablet} {
      max-width: 336px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
    }
    @media ${device.desktopXL} {
      max-width: 458px;
    }
  }
`

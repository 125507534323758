import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: #07605B;

  p {
    font-family: 'Sora'!important;
  }

  a {
    background-color: #FFFFFF;
    color: #07605B!important;

    @media ${device.desktopLG} {
      max-width: 440px;
    }

    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`

export const Circle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #FFF2E7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import useUtms from 'src/hooks/useUtms'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

import Hero from './sections/hero/_index'
import CapitalDeGiro from './sections/capital-de-giro/_index'
import FGIPEAC from './sections/egip-eac/_index'
import SimplifiqueSeuNegocio from './sections/simplifique-seu-negocio/_index'
import ComoContratar from './sections/como-contratar/_index'
import Faq from './sections/faq/_index'

const ContaDigitalPJ = () => {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }
  return (
    <Wrapper>
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
        <Hero />
        <CapitalDeGiro />
        <FGIPEAC />
        <SimplifiqueSeuNegocio />
        <ComoContratar />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPJ

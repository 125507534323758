import styled from 'styled-components'
import { green, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .abrir-conta {
    background: ${green['500']};

    @media ${device.tablet} {
      max-width: 336px;
    }

    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }

  .disponibilidade {
    background: ${white};
    color: #07605B;
    border: 1px solid #80BCBA;

    @media ${device.tablet} {
      max-width: 336px;
    }

    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }
`

export const Circle = styled.div`
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #07605B;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

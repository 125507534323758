import React from 'react'
import pageContext from '../../pageContext.json'

// Components
import Faq from 'src/components/Faq/index'

import {
  PerguntasFrequentes,
} from './style'

const FaqContaDigitalPJ = () => {
  return (
    <PerguntasFrequentes id='perguntas-frequentes' className='bg-gray py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='fs-24 lh-28 fs-md-40 lh-md-46 text-grayscale--500 mb-2 mb-lg-1'>Perguntas frequentes sobre Capital de Giro</h3>
          </div>
          <Faq id='conta-pj' className='px-0' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} answerData={pageContext.structuredData.faq} searchBg='#fff' />
        </div>
      </div>
    </PerguntasFrequentes>
  )
}

export default FaqContaDigitalPJ

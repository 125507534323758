import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import useUtms from 'src/hooks/useUtms'

import { Section, Circle } from './style'

const SimplifiqueSeuNegocio = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  return (
    <Section id='vantagens-pra-voce' className='bg-grayscale--100 d-flex justify-content-center align-items-center'>
      <div className='container py-5'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 fs-lg-32 fs-xl-36 lh-30 lh-lg-40 text-white'>
              <span className='d-block'>Simplifique seu negócio</span> <span className='d-block'>com capital de giro e um</span> <span className='d-block'>Super App completo de</span> verdade
            </h2>
            <div className='mt-4 d-none d-md-block'>
              <a
                href={utmLink}
                target='_blank'
                rel='noreferrer'
                data-home-pj='hero'
                className='fs-14 btn text-none rounded-3 my-2 fw-700'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    section_name: 'Simplifique seu negócio com capital de giro e um Super App completo de verdade',
                    element_name: 'Abrir Conta PJ gratuita',
                    redirect_url: utmLink,
                  })
                }}
              >
                Abrir Conta PJ gratuita
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-6 px-lg-0 pr-xl-2'>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='digital-account' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Conta 100%</span> gratuita e digital</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='pix' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Pix sem taxas</span> e ilimitados</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='card' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Cartão de crédito</span> sem anuidade</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='inter-loop' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Programa de</span> Pontos flexível</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='boleto-success' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Emissão de boletos</span> gratuitos todo mês</p>
                </div>
              </div>
              <div className='col-6 px-lg-0 pr-xl-2'>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='product-many' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Gestão de</span> <span className='d-block d-xl-inline'>cobranças</span> robusta</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='investments' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Plataforma de</span> <span className='d-block d-xl-inline'>investimentos</span> completa</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='i-safe' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mb-0 fw-700'><span className='d-block'>Marketplace</span> com cashback</p>
                </div>
                <div className='d-flex justify-content-start mt-2 mt-lg-3'>
                  <Circle className='mr-3 d-flex justify-content-center align-items-center circle-icon'>
                    <OrangeIcon size='MD' color='#07605B' icon='add' />
                  </Circle>
                  <p className='fs-12 fs-lg-18 lh-15 lh-lg-22 text-white mt-2 mb-0 fw-700'>E muito mais!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 d-md-none'>
          <a
            href={utmLink}
            target='_blank'
            rel='noreferrer'
            data-home-pj='hero'
            className='fs-14 btn text-white btn-orange--extra text-none rounded-3 my-2 fw-700'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                section_name: 'Por que abrir uma conta PJ Inter Empresas?',
                element_name: 'Abrir Conta PJ gratuita',
                redirect_url: utmLink,
              })
            }}
          >
            Abrir Conta PJ gratuita
          </a>
        </div>
      </div>
    </Section>
  )
}

export default SimplifiqueSeuNegocio

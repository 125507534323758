import React from 'react'

// Components
import ImageWebp from 'src/components/ImageWebp'

const CapitalDeGiro = () => {
  const arrayNumbers: number[] = [
    312, 336, 376, 456,
  ]

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-3 fw-600 text-grayscale--500'>
              O que é capital de giro?
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-xl-22 fw-400 text-grayscale--500'>
              O Capital de Giro é uma solução de crédito que garante fluxo de caixa para sua empresa investir em crescimento.
            </p>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-xl-22 fw-400 text-grayscale--500'>
              Ele pode ser utilizado para a compra de equipamentos, aumento de estoque, pagamento de fornecedores e muito mais.
            </p>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-xl-22 fw-400 text-grayscale--500'>
              Esta é a alavanca que falta para levar o seu negócio além!
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/capital-giro-pj-dobra2/image.webp'
              altDescription='Atendente de balcão em um restaurante.'
              arrayNumbers={arrayNumbers}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CapitalDeGiro

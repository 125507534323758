import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Card = styled.div`
  border-bottom: 1px solid #DEDFE4;

  @media ${device.tablet} { 
    border-radius: 10px;
    border: 1px solid #F5F6FA;
    height: 180px;
  }

  @media ${device.desktopLG} {
    height: 227px;
  }
`

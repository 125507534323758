import styled from 'styled-components'
import { white, grayscale } from 'src/styles/colors'

export const PerguntasFrequentes = styled.section`
  .summary-details {
    .summary {
      .summary-content {
        align-items: center;
        p {
          color: ${grayscale[500]}
        }
        span {
          color: ${grayscale[500]}
        }
      }
    }
  }

  .search-input {
    background: ${white};
  }
`
